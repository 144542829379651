import React from "react"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { DefaultHeader } from "components/headers/default-header"
import { Seo } from "components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import MapContainer from "components/map-container"
import { graphql } from "gatsby"

const items = [
  { title: "公司名称", content: "X-HACK公司" },
  {
    title: "位置",
    content: "〒107-0062 东京都港区南青山3-15-9MINOWA表参道3楼-251",
  },
  { title: "代表董事", content: "松田 信介" },
  { title: "资本存量", content: "1,000,000日元" },
  { title: "设立", content: "2018年3月14日" },
  {
    title: "商业活动",
    content: `开发和运营Twitter即时赢家 PARKLoT\n互联网服务业务\n咨询业务`,
  },
  {
    title: "访问",
    content: "从东京地铁银座线半蔵门线A4出口步行3分钟。",
  },
]

const Company = ({ location, data }) => {
  const {
    allMemberJson: { nodes: members },
  } = data
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle="公司介绍"
          pagepath={location.pathname}
          pagedesc="公司介绍 |PARKLoT提供了一个Twitter活动工具，可以 随心所欲 地运行，只需支付 月费。"
        />
        <main className="information">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container">
              <h1 className="text-white font-bold">公司理念、公司信息和成员</h1>
            </div>
          </section>
          <section className="information__philosophy bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="text-xl md:text-2xl text-title py-2 pl-6 mb-6 relative md:pl-8 md:mb-12">
                公司理念
              </h2>
              <h3 className="text-title font-bold mb-4 md:mb-6">
                用信息技术的力量赋予社会权力
              </h3>
              <p className="text-text md:text-xl">
                我们的使命是增强每一个在世界范围内工作的人的能力。
                <br />
                现在，远程工作已经很普遍，因此，人们比以往任何时候都更需要信息共享和业务效率的软件。
                <br />
                互联网社会极有可能进一步发展，一个工作地点、时间、语言和国籍都不再受到限制的社会将会到来。
                <br />
                日本公司正面临着进行重大改革的压力，以使多元化的劳动力在一个劳动力萎缩的国家中蓬勃发展。
                <br />
                通过提供软件，我们使多样化的工作方式成为可能，并建立了世界上的机制，以进一步增强劳动者的能力。
              </p>
            </div>
          </section>
          <section className="information__about bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="text-xl md:text-2xl text-title py-2 pl-6 mb-6 relative md:pl-8 md:mb-12">
                关于我们
              </h2>
              <table className="border-collapse w-full border-t border-lightgrey border-b mb-6 md:mb-12">
                <tbody>
                  {items.map((item, index) => {
                    return (
                      <tr key={index} className="border-b border-lightgrey">
                        <th className="text-text block px-3 pt-5 pb-2 md:pt-6 md:pb-6 md:w-4/12 md:table-cell">
                          {item.title}
                        </th>
                        <td className="text-text whitespace-pre-wrap px-3 pb-5 block md:table-cell md:pt-6 md:pb-6">
                          {item.content}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <MapContainer />
            </div>
          </section>
          <section className="information__members bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="text-xl md:text-2xl text-title py-2 pl-6 mb-6 relative md:pl-8 md:mb-12">
                会员
              </h2>
              <div className="flex w-full flex-col gap-14 md:flex-row md:flex-wrap md:gap-20">
                {members.map(member => {
                  return (
                    <div key={member.id} className="information__member-photo">
                      <GatsbyImage
                        image={getImage(member.image)}
                        alt={member.name}
                        className="mb-6 rounded h-60"
                      />
                      <p className="text-title text-sm mb-1">
                        {member.position}
                      </p>
                      <p className="font-bold text-title text-xl mb-2">
                        {member.name}
                      </p>
                      <p className="text-text">{member.comment}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          <section className="information__cta bg-primary">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="cta text-white text-center mb-4 font-bold">
                SNS活动工具，
                <br />
                加速获取粉丝和促进销售
              </h2>
              <p className="text-white text-center md:text-xl mb-8">
                我们也可以讨论你的活动的规划内容。
                <br className="block md:hidden" />
                请随时与我们联系。
              </p>
              <div className="rounded-lg bg-white py-6 px-4 md:p-10">
                <div className="m-auto mb-4 text-center w-11/12 md:w-7/12">
                  <StaticImage
                    layout="constrained"
                    alt="3分钟内展示PARKLoT功能的文件"
                    src="../../images/company/comp_doc.png"
                  />
                </div>
                <div className="m-auto mb-8 md:mb-14 text-center">
                  <a
                    className="btn-features_contact border text-base md:text-2xl hover:text-accent hover:bg-white hover:border"
                    href="https://hub.park-lot.com/document-download"
                    target="_blank"
                    rel="noreferrer"
                  >
                    下载一份3分钟文件，
                    <br className="block md:hidden" />
                    了解PARKLoT如何运作（免费的）
                  </a>
                </div>
                {/* その他お問合せ */}
                <div className="flex flex-col gap-4 md:flex-row md:justify-between md:gap-14 items-stretch text-center">
                  <div className="rounded-lg bg-palegrey p-4 md:py-6 md:px-8 w-full flex items-center flex-col justify-center">
                    <h4 className="font-bold text-xl text-text mb-3">
                      通过电话联系
                    </h4>
                    <p className="mb-3">
                      <a
                        href="tel:0368210143"
                        className="text-2xl text-accent eng-num font-bold"
                      >
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="text-accent inline-block mr-2"
                        />
                        050-3528-8481
                      </a>
                    </p>
                    <p className="text-xs text-text">
                      (9:00 - 19:00 / 周六、周日和公共假期除外)
                    </p>
                  </div>
                  <div className="rounded-lg bg-palegrey p-4 md:py-6 md:px-8 w-full">
                    <h4 className="font-bold text-xl text-text mb-3">
                      免费在线咨询
                    </h4>
                    <a
                      className="bg-primary text-white font-bold py-3 border px-6 inline-block mb-3 rounded hover:text-primary hover:bg-white hover:border"
                      href="https://hub.park-lot.com/free-consulting"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      检查可用的预订时间
                    </a>
                    <p className="text-xs text-text">
                      关于与我们的服务合作或提出产品或服务的更多信息，
                      <br />
                      请使用
                      <a
                        href="https://hub.park-lot.com/contact"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="underline text-accent"
                      >
                        此表格
                      </a>
                      与我们联系。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetMembers {
    allMemberJson {
      nodes {
        id
        name
        position
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        comment
      }
    }
  }
`

export default Company
